export default class LevelRequestItem {
    page = 1;
    pageSize = 10;
    parentId = -1;
    defaultId = -1;
    search = false;
    searchField = "all";
    searchKey = "";
    cType: [string, number] = null;
}
